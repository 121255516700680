import { FuseAdminUserModel, ITableColumns, FormOptions } from "@kiyata/fuse";

const extraColumns: ITableColumns[] = [
    { label_name: 'Signature', column_name: 'esignature', image: { type: 'base64' }, hideSort: true }
];

const addFormDetailJSON: FormOptions[] = [
   
];

const editFormDetailJSON: FormOptions[] = [
  
    {
        controlName: 'esignature',
        label: 'Signature',
        type: 'base64',
        uploadFile: false,
        changeFile: false
    }
];

export const adminUserModel: FuseAdminUserModel = {
    displayFullName: false,
    extraColumns: extraColumns,
    addFormDetailJSON: addFormDetailJSON,
    editFormDetailJSON: editFormDetailJSON
}