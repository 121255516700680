/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@kiyata/fuse';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'Calendar',
    title: 'Calendar',
    type: 'basic',
    icon: "heroicons_solid:calendar-days",
    link: '/dashboard'
  },
  {
    id: 'MySpace',
    title: 'My Space',
    type: 'collapsable',
    icon: 'heroicons_solid:user-circle',
    children: [
      {
        id: 'MySpace.Booking',
        title: 'Leave Requests',
        type: 'basic',
        icon: 'edit_calendar',
        link: '/myspace/leave'
      },
      {
        id: 'MySpace.Entitlement',
        title: 'Entitlements',
        type: 'basic',
        icon: 'mat_solid:calendar_today',
        link: '/myspace/entitlement'
      },
      {
        id: 'MySpace.Cash',
        title: 'Cash Advances',
        type: 'basic',
        icon: 'heroicons_solid:currency-dollar',
        link: '/myspace/cash'
      },
      {
        id: 'MySpace.Expense',
        title: 'Expenses',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/myspace/expense'
      },
      {
        id: 'MySpace.Profile',
        title: 'Account',
        type: 'basic',
        icon: 'heroicons_solid:user-circle',
        link: '/myspace/account'
      }
    ]
  },
  {
    id: 'MyTeam',
    title: 'My Team',
    type: 'collapsable',
    icon: 'heroicons_solid:user-group',
    children: [
      {
        id: 'MyTeam.Booking',
        title: 'Leave Requests',
        type: 'basic',
        icon: 'edit_calendar',
        link: '/team/leave'
      },
      {
        id: 'MyTeam.Entitlement',
        title: 'Leave Entitlements',
        type: 'basic',
        icon: 'mat_solid:calendar_today',
        link: '/team/entitlement'
      },
      {
        id: 'MyTeam.Expense',
        title: 'Expenses',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/team/expense'
      },
      {
        id: 'Team.Cash',
        title: 'Cash Advances',
        type: 'basic',
        icon: 'heroicons_solid:currency-dollar',
        link: '/team/cash'
      }
    ]
  },
  {
    id: 'Management',
    title: 'Management',
    type: 'collapsable',
    icon: 'dashboard',
    children: [
      {
        id: 'Management.Account',
        title: 'Account',
        type: 'basic',
        icon: 'mat_solid:account_balance',
        link: '/finance/account'
      },
      {
        id: 'Management.Budgets',
        title: 'Budgets',
        type: 'basic',
        icon: 'monetization_on',
        link: '/finance/budget'
      },
      {
        id: 'Management.Donors',
        title: 'Donors',
        type: 'basic',
        icon: 'mat_solid:account_balance_wallet',
        link: '/donor'
      },
      {
        id: 'Management.Donations',
        title: 'Donations',
        type: 'basic',
        icon: 'attach_money',
        link: '/finance/donation'
      },
      {
        id: 'Management.Banking',
        title: 'Banking',
        type: 'basic',
        icon: 'business',
        link: '/finance/bank'
      }
    ]
  },
  {
    id: 'ProjectManagement',
    title: 'Projects',
    type: 'collapsable',
    icon: 'mat_solid:featured_play_list',
    children: [
      {
        id: 'Projects.Project',
        title: 'Projects',
        type: 'basic',
        icon: 'mat_solid:featured_play_list',
        link: '/project'
      },
      {
        id: 'Projects.Events',
        title: 'Events',
        type: 'basic',
        icon: 'event',
        link: '/event'
      },
    ]
  },
  {
    id: 'Finance',
    title: 'Finance',
    type: 'collapsable',
    icon: 'mat_solid:attach_money',
    children: [
      {
        id: 'Finance.Invoices',
        title: 'Bills',
        type: 'basic',
        icon: 'heroicons_solid:currency-dollar',
        link: '/finance/bill'
      },
      {
        id: 'Finance.Expenses',
        title: 'Expenses',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/finance/expense'
      },
      {
        id: 'Suppliers',
        title: 'Suppliers',
        type: 'basic',
        icon: 'mat_solid:supervised_user_circle',
        link: '/supplier'
      },
      {
        id: 'Finance.Cash',
        title: 'Cash Advances',
        type: 'basic',
        icon: 'heroicons_solid:currency-dollar',
        link: '/finance/cash'
      }
    ]
  },
  {
    id: 'Reports',
    title: 'Reports',
    type: 'collapsable',
    icon: 'heroicons_solid:chart-bar',
    children: [
      {
        id: 'Reports.Overview',
        title: 'Overview',
        type: 'basic',
        icon: 'heroicons_solid:chart-bar',
        link: '/report/overview'
      },
      {
        id: 'Reports.Assets',
        title: 'Assets',
        type: 'basic',
        icon: 'heroicons_solid:chart-bar-square',
        link: '/report/assets'
      },
      {
        id: 'Reports.Staff&Volunteers',
        title: 'Staff & Volunteers',
        type: 'basic',
        icon: 'heroicons_solid:chart-pie',
        link: '/report/staff-and-volunteers'
      },
      {
        id: 'Reports.Expenses',
        title: 'Expenses',
        type: 'basic',
        icon: 'heroicons_solid:document-chart-bar',
        link: '/report/expenses'
      },
      {
        id: 'Reports.Inventory',
        title: 'Inventory',
        type: 'basic',
        icon: 'heroicons_solid:chart-bar-square',
        link: '/report/inventory'
      }
    ]
  },
  {
    id: 'AssetsAndInventory',
    title: 'Assets & Inventory',
    type: 'collapsable',
    icon: 'heroicons_solid:folder-open',
    children: [
      {
        id: 'Assets',
        title: 'Assets',
        type: 'basic',
        icon: 'heroicons_solid:computer-desktop',
        link: '/asset'
      },
      {
        id: 'Inventory',
        title: 'Inventory',
        type: 'basic',
        icon: 'mat_solid:format_list_numbered_rtl',
        link: '/inventory'
      }
    ]
  },
  {
    id: 'HR',
    title: 'HR',
    type: 'collapsable',
    icon: 'mat_solid:people',
    children: [
      {
        id: 'HR.Employees',
        title: 'Employees',
        type: 'basic',
        icon: 'mat_solid:people',
        link: '/hr/employee'
      },
      {
        id: 'HR.Entitlement',
        title: 'Leave Entitlements',
        type: 'basic',
        icon: 'mat_solid:calendar_today',
        link: '/hr/entitlement'
      },
      {
        id: 'HR.Holiday',
        title: 'Public Holidays',
        type: 'basic',
        icon: 'heroicons_outline:calendar-days',
        link: '/hr/holiday'
      },
      {
        id: 'HR.Departments',
        title: 'Departments',
        type: 'basic',
        icon: "heroicons_outline:building-office-2",
        link: '/hr/department'
      },
      {
        id: 'HR.JD',
        title: 'Job descriptions',
        type: 'basic',
        icon: 'heroicons_solid:document-text',
        link: '/hr/jobDescription'
      },
      {
        id: 'HR.Volunteers',
        title: 'Volunteers',
        type: 'basic',
        icon: 'mat_solid:people',
        link: '/hr/volunteer'
      },
    ]
  },
  {
    id: 'Recruitment',
    title: 'Recruitment',
    type: 'collapsable',
    icon: 'heroicons_solid:briefcase',
    children: [
      {
        id: 'Vacancy',
        title: 'Vacancies',
        type: 'basic',
        icon: 'heroicons_solid:user-plus',
        link: '/recruitment/vacancy'
      },
      {
        id: 'Applications',
        title: 'Job applications',
        type: 'basic',
        icon: 'heroicons_solid:document-duplicate',
        link: '/recruitment/application'
      }
    ]
  },
  {
    id: 'Organization',
    title: 'Organization',
    type: 'collapsable',
    icon: 'mat_solid:business',
    link: '/organization',
    children: [
      {
        id: 'Departments',
        title: 'Departments',
        type: 'basic',
        icon: "heroicons_outline:building-office-2",
        link: '/organization/department'
      },
      {
        id: 'OrgChart',
        title: 'OrgChart',
        type: 'basic',
        icon: "mat_solid:account_tree",
        link: '/organization/chart'
      },
    ]
  },
//   {
//     id: 'divider-1',
//     type: 'divider'
//   },
  {
    id: 'MyOrgChart',
    title: 'OrgChart',
    type: 'basic',
    icon: "mat_solid:account_tree",
    link: '/organization/chart'
  },
  {
    id: 'Leave.Holiday',
    title: 'Public Holidays',
    type: 'basic',
    icon: 'heroicons_outline:calendar-days',
    link: '/leave/holiday'
  },

  {
    id: 'GUIDES',
    title: 'Help & Support',
    type: 'basic',
    icon: 'mat_solid:help',
    link: '/help'
  },
  {
    id: 'divider-1',
    type: 'divider'
  },
  {
    id: 'ADMIN_GROUP',
    title: 'Admin',
    type: 'group',
    icon: 'mat_solid:settings',
    children: [
      {
        id: 'ADMIN.USERS',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_solid:users',
        link: '/admin/user',
      },
      {
        id: 'ADMIN_GROUP',
        title: 'Help',
        type: 'collapsable',
        icon: 'mat_solid:help',
        children: [
          {
            id: 'ADMIN.GUIDES_CATEGORY',
            title: 'Help Categories',
            type: 'basic',
            icon: 'mat_solid:help',
            link: '/admin/help-category'
          },
          {
            id: 'ADMIN.GUIDES_HELP',
            title: 'Help articles',
            type: 'basic',
            icon: 'mat_outline:help_outline',
            link: '/admin/help'
          }
        ]
      }
    ]
  }
];

export const compactNavigation: FuseNavigationItem[] = [...defaultNavigation];
export const futuristicNavigation: FuseNavigationItem[] = [...defaultNavigation];
export const horizontalNavigation: FuseNavigationItem[] = [...defaultNavigation];
