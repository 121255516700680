export const environment = {
    production: true,
    envName: 'PROD',
    tenantName: process.env.TENANT,
    serviceId: 8246,
    serviceName: 'NGOPROClient',
    serviceToken: 'dssTokenNGOPRO',
    serviceUser: 'dssUserNGOPRO',
    backendUrl: 'https://backend.nepwu.org',
    authAPIUrl: 'https://authbackend.nepwu.org',
    loginUrl: 'https://authbackend.nepwu.org',
    refDataUrl: 'https://refdata.nepwu.org',
    paymentUrl: 'https://payment.nepwu.org',
    onlinePaymentUrl: 'https://online-payment.nepwu.org',
  };
