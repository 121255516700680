import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { filter } from 'rxjs/operators';
import { SharedService, VersionCheckService, TitleService, ICountryInfo, SettingComponent, IdleService } from '@kiyata/fuse';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends SettingComponent implements OnInit {
    authDetail: any;
    busySpinner: boolean = false;
    alertType: any = 'primary';
    alertMessage: string = '';
    /**
     * Constructor
     */
    constructor(private _versionCheckService: VersionCheckService,
        protected _sharedService: SharedService,
        private cd: ChangeDetectorRef,
        private _router: Router,
        private titleService: TitleService,
        private idleService: IdleService
    ) {
        super(_sharedService);
        this.getCountryInfo();
    }

    ngOnInit(): void {
        const versionCheckURL = window.location.origin;
        if (versionCheckURL.indexOf('localhost') === -1) {
            this._versionCheckService.initVersionCheck(versionCheckURL);
        }

        this._router.events
            .pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel))
            .subscribe(() => {
                this._sharedService.searchHeader.next({});
            });

        this._router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {

            });

        this.idleService.initialize();
    }

    ngAfterViewInit(): void {
        this._sharedService.authDetail.subscribe((authDetail) => {
            this.authDetail = authDetail;
            if (this.authDetail.userId) {
            }
        });

        this._sharedService.busySpinner.subscribe((show) => {
            this.busySpinner = show;
            this.cd.detectChanges();
        });

        this._sharedService.alertView.subscribe((alert) => {
            if (alert) {
                this.alertType = alert.type;
                this.alertMessage = alert.message;
                const time = alert.milliseconds || 2000;
                this._sharedService._fuseAlertService.show('alertBox');
                setTimeout(() => {
                    this._sharedService._fuseAlertService.dismiss('alertBox');
                }, time);
            }
        });
    }

    getCountryInfo(): void {
        if (isEmpty(this._sharedService.countryInfo)) {
            this._sharedService.getCountrySettings().then((res) => {
                if (res) {
                    const countryInfo = res as ICountryInfo;
                    this._sharedService.setCountryInfo(countryInfo);
                    this.updateIndexPage(countryInfo);
                    this.titleService.init();
                } else {
                    this.titleService.init();
                    this._sharedService.alertView.next({ type: 'error', message: "Unable to load application settings", milliseconds: 5000 });
                }
            });
        } else {
            this.titleService.init();
            this._sharedService.alertView.next({ type: 'error', message: "Unable to load application settings", milliseconds: 5000 });
        }
    }

}
