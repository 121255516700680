import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';

import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';


import { KiyataAuthInterceptor } from '@kiyata/fuse';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { NgIdleModule } from '@ng-idle/core';

import {
    KiyataFuseModule, FuseSharedConfig, SharedModule,
    FuseModule, FuseConfigModule, FuseMockApiModule, LayoutModule,
    VersionCheckService, TitleService, FuseProgressBarModule
} from '@kiyata/fuse';

import { environment } from 'environments/environment';
import * as Const from 'app/shared/shared.const';
import { adminUserModel } from 'app/shared/admin-user-model';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

const kiyataFuseConfig: FuseSharedConfig = {
    appConfig: appConfig,
    backofficeService: true,
    environment: environment,
    hasFooterVisible: Const.hasFooterVisible,
    REDIRECT_PATHS: Const.REDIRECT_PATHS,
    afterLoginPageLink: '/dashboard',
    adminUserModel: adminUserModel,
    newLoginDesign: true
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        FuseProgressBarModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,
        SharedModule,
        // Layout module of your application
        LayoutModule,
        KiyataFuseModule.forRoot(kiyataFuseConfig),
        NgIdleModule.forRoot()
    ],
    providers: [
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KiyataAuthInterceptor,
            multi: true
        },
        TitleService,
        VersionCheckService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
