<div class="w-full fixed flex justify-center z-99999 mt-36 items-center fuse-alert pointer-events-none">
    <fuse-alert class="pointer-events-auto" [type]="alertType" [dismissible]="true" [dismissed]="true"
      [appearance]="'fill'" [name]="'alertBox'">
      <span fuseAlertTitle>{{alertMessage}}</span>
    </fuse-alert>
  </div>
  <div class="w-full fixed flex justify-center z-99999 h-full items-center busy-spinner" *ngIf="busySpinner">
    <div class="absolute bg-black opacity-50 w-full h-full"></div>
    <mat-progress-spinner [diameter]="100" [mode]="'indeterminate'">
    </mat-progress-spinner>
  </div>
  <fuse-progress-bar></fuse-progress-bar>
  <router-outlet></router-outlet>