export const REDIRECT_PATHS: string[] = [];
export const hasFooterVisible: boolean = false;

export const pendingStatus: string = "PENDING";
export const approvedStatus: string = "APPROVED";
export const rejectedStatus: string = "REJECTED";
export const activeStatus: string = "ACTIVE";
export const suspendedStatus: string = "SUSPENDED";
export const closedStatus: string = "CLOSED";
export const draftStatus: string = "DRAFT";


export enum EnumWorkflowCategory {
    GENERIC = 0,
    DONOR = 1,
    DONATION = 2,
    BANK = 3,
    PROJECT = 4,
    EVENT = 5,
    BILL = 6,
    EXPENSE = 7,
    USER_EXPENSE = 71,
    SUPPLIER = 8,
    CASHADVANCE = 9,
    USER_CASHADVANCE = 91,
    LEAVE = 10,
    USER_LEAVE = 101,
    ASSET = 11,
    INVENTORY = 12,
    VACANCY = 13,
    APPLICATION = 14,
    EMPLOYEE = 15
}

export const relationshipList: string[] = ["Spouse", "Brother", "Sister", "Relative", "Parents"];
export const companyTypeList: string[] = ["Individual", "Organization", "NGO"];
export const donorTypeList: string[] = ["Individual", "Organization", "Government"];
export const currencyList: string[] = ["SSP", "USD", "GBP", "AUD", "CAD", "EUR"];
export const bankTypeList: string[] = ["Savings", "Current", "Credit/loan"];
export const billCategoryList: string[] = ["Rent", "Electricity", "Gas", "Cleaning", "Health"];
export const expenseCategoryList: string[] = ["Transport", "Accommodation", "Per diem", "Equipment", "Administrative"];
export const paymentMethodList: any[] = ["Cash", "Online", "Wire", "Check", "Bank draft"];

export const assetsCategoryList: any[] = ["Vehicle", "Laptop", "Printer", "Smartphone", "Desk", "Chair", "Accessory"];
export const assetsMethodList: any[] = ["Donation", "Purchase"];

export const departmentList: string[] = ["Finance", "operations", "Vaccinations", 'Training & Education', "Management"];
export const jobTitleList: string[] = ['Director', 'Coordinator', 'Project manager', 'Field employee', 'Accountant'];

export const leaveTypeList: string[] = ["Annual Leave", "Sick Leave", "Long Illness", "Administrative Leave", "Career Break", "Unauthorized Leave", "Education/Training"];
export const leaveStatusList: string[] = ["DRAFT", "APPROVED", "CANCELLED", "REJECTED"];

export const helpCategoryStatusList: string[] = ["ACTIVE"];
export const helpStatusList: string[] = ["ACTIVE"];

export const slugify = (str) => {
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
};

