import { Route } from '@angular/router';
import { KiyataAuthGuard, KiyataNoAuthGuard, KiyataSubscribeGuard } from '@kiyata/fuse';
import { LayoutComponent } from '@kiyata/fuse';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
// tslint:disable:max-line-length
// @ts-ignore
export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    {
        path: 'signed-in-redirect',
        canActivate: [KiyataAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        loadChildren: () => import('@kiyata/fuse').then(m => m.KiyataSignedInRedirectModule)
    },

    {
        path: '',
        canActivate: [KiyataNoAuthGuard],
        canActivateChild: [KiyataNoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        loadChildren: () => import('@kiyata/fuse').then(m => m.KiyataAuthModule)
    },
    {
        path: '',
        canActivate: [KiyataAuthGuard],
        canActivateChild: [KiyataAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('@kiyata/fuse').then(m => m.KiyataAuthSignOutModule) },
        ]
    },
    {
        path: '',
        canActivate: [KiyataSubscribeGuard],
        canActivateChild: [KiyataSubscribeGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () => import('app/modules/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        loadChildren: () => import('@kiyata/fuse').then(m => m.ErrorPagesModule)
    }
];
